<template>
  <div class="register">
    <van-cell-group
      class="register-trackingNo"
      :title="$t('登记单号，选择您需要的增值服务')"
    >
      <van-field
        size="large"
        :label="$t('运单号')"
        :placeholder="$t('请输入运单号')"
        maxlength="60"
        v-model="cargo.trackingNo"
        clearable
      />
      <van-cell
        center
        size="large"
        :title="$t('运输方式')"
        title-style="width:110px"
      >
        <van-radio-group v-model="cargo.expressWay">
          <div style="display: flex">
            <div style="margin-right: 20px">
              <van-radio name="1">{{ $t("陆运") }}</van-radio>
            </div>
            <div>
              <van-radio name="2">{{ $t("海运") }}</van-radio>
            </div>
          </div>
          <!-- <div
            v-if="cargo.expressWay == 2"
            style="margin-top: 5px; text-align: left"
          >
            {{ $t("海运不满30KG或0.2立方，按30KG收费") }}
          </div> -->
        </van-radio-group>
        <!-- <div style="text-align: left">    
      <van-tag plain round bind:tap="onTagSelected" data-field="cargo.expressWay" data-value="1" color = "{{  cargo.expressWay == 1 ?  '#f2826a' : '' }}" custom-class="custom-tag mr-10" style="margin-right: 10px">陆运</van-tag>
      <van-tag plain round bind:tap="onTagSelected" data-field="cargo.expressWay" data-value="2" color = "{{  cargo.expressWay == 2 ?  '#f2826a' : '' }}" custom-class="custom-tag">海运</van-tag> 
    </div> -->
      </van-cell>
    </van-cell-group>

    <van-cell-group :title="$t('增值服务')">
      <div v-if="!addedValue.reject">
        <van-cell
          :title="$t('保价')"
          size="large"
          :label="
            insurance.declareAmount
              ? $t('保价金额，保费', {
                  declareAmount: insurance.declareAmount,
                  insuranceFee: insurance.insuranceFee,
                })
              : $t('未保价运单按运费三倍赔偿')
          "
          :to="{
            name: 'insurance',
            query: {
              declareAmount: insurance.declareAmount || 0,
              insuranceFee: insurance.insuranceFee || 0,
              insurance: addedValueConfig.insurance,
              insuranceLimitAmount: addedValueConfig.insuranceLimitAmount,
            },
          }"
          is-link
        >
          <!-- <van-switch data-field="addedValue.insurance" bind:change="onChange" checked="{{ addedValue.insurance ? true : false }}" size="28px"/> -->
        </van-cell>

        <van-cell center :title="$t('打木架')" size="large">
          <van-switch v-model="addedValue.woodenframe" size="28px" />
        </van-cell>
        <van-cell
          center
          :title="$t('到付快递费')"
          :label="$t('按中国仓实付金额收费')"
          size="large"
        >
          <van-switch v-model="addedValue.deliveryFeeOnReceiver" size="28px" />
        </van-cell>
      </div>
      <van-cell center :title="$t('中国仓退回')" size="large">
        <van-switch v-model="addedValue.reject" size="28px" />
      </van-cell>
    </van-cell-group>

    <van-cell-group :title="$t('备注')">
      <van-field
        size="large"
        autosize
        clearable
        type="textarea"
        :placeholder="$t('请输入备注')"
        v-model="cargo.remark"
      />
    </van-cell-group>

    <div style="margin-top: 5px; padding: 10px 15px">
      <div style="display: flex; font-size: 14px; align-items: center">
        <van-checkbox
          v-model="cargo.agreeTerms"
          custom-class="terms"
          checked-color="#07c160"
          icon-size="18px"
        >
        </van-checkbox>
        <div style="margin-left: 10px">{{ $t("我已阅读并同意") }}</div>
        <div
          @click="toWebPage('https://zy.yfwl.xyz/agreement.html')"
          style="color: #569cd6"
        >
          {{ $t("《服务条款》") }}
        </div>
      </div>
      <div
        v-if="!cargo.agreeTerms"
        style="padding-left: 16px"
        class="error-message"
      >
        {{ errorMessages.agreeTerms.agree }}
      </div>
    </div>

    <div class="saveButton">
      <van-button
        round
        block
        @click="doSubmit"
        type="primary"
        :loading="submitLoading"
        :disabled="submitLoading"
      >
        {{ $t("确定") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Field,
  RadioGroup,
  Cell,
  CellGroup,
  Radio,
  Switch,
  Toast,
  Checkbox,
  Button,
} from "vant";

import { getDefaultContact, getAddedValues, register } from "@/service/api";
import { temp } from "@/utils/temp";

export default {
  components: {
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Radio.name]: Radio,
    [Switch.name]: Switch,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  data() {
    return {
      errorMessages: {
        trackingNo: {
          required: this.$t("请填写运单号"),
          maxlength: this.$t("单号最多输入60个字符"),
          onlyWordAndNumber: this.$t(
            "单号只能输入字母、数字和横线,长度不超过60"
          ),
        },
        expressWay: {
          required: this.$t("请选择运输方式"),
        },
        agreeTerms: {
          agree: this.$t("请先阅读并同意服务条款"),
        },
      },
      submitLoading: false,
      cargo: {
        expressWay: "1",
        agreeTerms: true,
      },
      value: "",
      addedValue: {},
      insurance: {
        declareAmount: null,
        insuranceFee: null,
      },
      defaultContact: {},
      addedValueConfig: {},
    };
  },
  mounted() {
    this.getDefaultContact();
    this.getAddedValues();
  },
  activated() {
    const insurance = temp.insurance;
    if (insurance) {
      this.insurance = insurance;
      temp.insurance = null;
    }
  },
  methods: {
    toWebPage(url) {
      window.open(url, "_blank");
    },
    async doSubmit() {
      const { trackingNo } = this.cargo;
      if (!trackingNo) {
        Toast(this.$t("请填写运单号"));
        return;
      }

      if (!/^[0-9a-zA-Z_\\-]+$/.test(trackingNo.trim())) {
        Toast(this.$t("单号只能输入字母、数字和横线,长度不超过60"));
        return;
      }

      if (!this.cargo.agreeTerms) {
        Toast(this.$t("请先阅读并同意服务条款"));
        return;
      }

      this.submitLoading = true;

      const payload = {
        cargo: {
          ...this.cargo,
          receiverName: this.defaultContact.name,
          receiverMobile: this.defaultContact.mobile,
          receiverAddress: this.defaultContact.address,
          receiverPostalCode: this.defaultContact.postalCode,
          receiverGeoCode: this.defaultContact.geoCode,
        },
        addedValues: {
          ...this.addedValue,
          insurance:
            this.insurance.declareAmount && this.insurance.declareAmount > 0,
          declareAmount: this.insurance.declareAmount
            ? parseFloat(this.insurance.declareAmount)
            : null,
          insuranceFee: this.insurance.insuranceFee
            ? parseFloat(this.insurance.insuranceFee)
            : null,
          currency: "THB",
        },
      };

      const res = await register(payload);

      if (res.success) {
        Toast.success(this.$t("登记成功"));
        this.cargo = {
          expressWay: "1",
          agreeTerms: true,
        };
        this.insurance = {};
        this.addedValue = {};

        this.$router.replace({ name: "success" });
      } else {
        Toast.fail(this.$t(res.message));
      }
      this.submitLoading = false;
    },
    async getDefaultContact() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getDefaultContact();
      if (res.success) {
        this.defaultContact = res.data;
      } else Toast.fail(res.message);

      Toast.clear();
    },
    async getAddedValues() {
      const res = await getAddedValues();
      if (res.success) {
        this.addedValueConfig = res.data;
      }
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   if (to.path == "/index") {
  //     to.meta.keepAlive = true;
  //   } else {
  //     to.meta.keepAlive = false;
  //   }
  // },
};
</script>
<style lang="less" scoped>
.page-title {
  background-color: #fff;
  font-size: 1.2rem;
  padding: 20px;
}
.saveButton {
  margin: 15px 15px 0 15px;
  padding-bottom: 15px;
}

.custom-tag {
  padding: 8px 20px !important;
  font-size: 16px !important;
}

.terms {
  padding: 10px 16px;
}

.terms-label {
  font-size: 14px;
}

.register-trackingNo {
  .van-cell__value {
    min-width: 70%;
  }
}
</style>